import * as React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({
  data: { previous, next, site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`


  let schemaData = {
    "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://collectivedress.com/${location.pathname}`
            },
            "headline": `${post.frontmatter.title}`,
            "description": `${post.frontmatter.description}`,
            "image": `${post.frontmatter.frontimage}`,
            "author": {
              "@type": "Organization",
              "name": `Collective Dress`
            },
            "publisher": {
              "@type": "Organization",
              "name": `Collective Dress`,
              "logo": {
                "@type": "ImageObject",
                "url":
                "https://www.collectivedress.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif"
              }
            },
            "datePublished": `${post.frontmatter.date}`,
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.collectivedress.com${location.pathname}`}
        />
        <title>{`${post.frontmatter.title} | Collective Dress`}</title>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      <article
        className="blog-post mt-8"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1
            className="text-2xl font-extrabold text-center mb-2"
            itemProp="headline"
          >
            {post.frontmatter.title}
          </h1>
          <p className="text-center text-xl font-bold">
            {post.frontmatter.date}
          </p>
        </header>
        <section
          className="mt-5"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer></footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        frontimage
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
